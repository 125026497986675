import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import GdLogo from "./GDLogo";

const GD_LOGO_WIDTH = 120;

const GDBanner = ({ domainName, banner, bannerClick }) => {
  return (
    <div data-testid="tdfsBannerExperiment" className="pw-banner">
      <GdLogo
        className="pw-banner-logo"
        width={GD_LOGO_WIDTH}
        color="#61EDEA"
      />
      <p className="pw-banner-text">
        <b>
          <FormattedMessage
            id="Banner-Main-Text-I"
            values={{
              domain: domainName,
            }}
          />
        </b>
        &nbsp;
        <FormattedMessage
          id="Banner-Main-Text-II"
          values={{
            domain: domainName,
          }}
        />
      </p>
      <a href={banner.link} onClick={() => bannerClick(banner.type)}>
        <button className="pw-banner-cta">
          <FormattedMessage
            id="Banner-Button-Text"
            values={{
              domain: domainName,
            }}
          />
        </button>
      </a>
    </div>
  );
};

GDBanner.propTypes = {
  domainName: PropTypes.string,
  bannerClick: PropTypes.func,
  banner: PropTypes.shape({
    link: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default GDBanner;
